var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"loading",rawName:"v-loading.fullscreen.lock",value:(_vm.loading),expression:"loading",modifiers:{"fullscreen":true,"lock":true}}],staticClass:"course"},[_c('div',{staticClass:"course_body"},[_c('div',{staticClass:"module"},[_vm._l((_vm.themes),function(item){return _c('ThemeCard',{key:item.id,attrs:{"item":item}})}),_c('div',{staticClass:"text-center"},[(!_vm.testing)?_c('button',{staticClass:"course-details-prev-btn",on:{"click":_vm.prev}},[_vm._v(" "+_vm._s(_vm.$t("Back"))+" ")]):_vm._e(),(!_vm.testing && !_vm.lastModule)?_c('button',{staticClass:"course-details-next-btn",class:{
              disabled: !_vm.nextModuleAvailable
            },on:{"click":_vm.next}},[_vm._v(" "+_vm._s(_vm.$t('Further'))+" ")]):_vm._e()]),(_vm.testing)?_c('div',{staticClass:"module_item testing",class:{
            'active': _vm.testing.available && _vm.testing.score === 0,
            'success': _vm.testResult,
            'fail': _vm.testing.score > 0 && !_vm.testResult,
            'noaccess': !_vm.testing.available
          }},[_c('div',{staticClass:"module_item_status"},[(!_vm.testing.available)?_c('div',[_c('img',{attrs:{"src":require("@/assets/course/close-icon.svg"),"alt":""}})]):(_vm.testing.score === 0)?_c('div',[_c('img',{attrs:{"src":require("@/assets/course/rotation-icon.svg"),"alt":""}})]):(_vm.testResult)?_c('div',[_c('img',{attrs:{"src":require("@/assets/course/success-icon.svg"),"alt":""}})]):_c('div')]),_c('div',{staticClass:"module_item_info"},[_c('div',{staticClass:"name"},[_vm._v(" Тестирование ")]),(_vm.testing.available)?_c('div',{staticClass:"buttons"},[_c('router-link',{staticClass:"btn",attrs:{"to":{
                  name: 'course-exercise',
                  params: { slug: _vm.$route.params.slug, quiz: _vm.testing.id },
                  query: { page: _vm.$route.query.page, type: _vm.$route.query.type }
                }}},[_vm._v(" Начать ")]),(false)?_c('button',{staticClass:"btn"},[_vm._v(" "+_vm._s(_vm.$t("Passed_course_module"))+" ")]):_vm._e(),(false)?_c('button',{staticClass:"btn"},[_vm._v(" Не пройдено ")]):_vm._e(),(_vm.testing.score > 0)?_c('div',{staticClass:"test-result"},[_c('div',{staticClass:"test-result_item"},[_vm._v(" "+_vm._s(_vm.$t("Passed_course_module"))+": "+_vm._s(_vm.testing['passedDate'])+" ")]),_c('span'),_c('div',{staticClass:"test-result_item"},[_vm._v(" Проходной балл: "+_vm._s(_vm.testing['minScore'])+"% ")])]):_vm._e(),(_vm.testing.score > 0 && _vm.testing.score < _vm.testing['minScore'])?_c('div',{staticClass:"retry"},[_vm._v(" Пройти еще раз ")]):_vm._e(),(_vm.testing.score > 0)?_c('div',{staticClass:"result"},[_vm._v(" "+_vm._s(_vm.testing.score)+"% ")]):_vm._e()],1):_c('div',{staticClass:"info"},[_c('div',{staticClass:"info_text"},[_vm._v(" "+_vm._s(_vm.$t('You_cannot_start_until_you_have_completed_the_previous_topic'))+" ")]),_c('img',{staticClass:"info_icon",attrs:{"src":require("@/assets/course/info-icon.svg"),"alt":""}})])])]):_vm._e()],2)]),_c('SideMenuNew')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }