<template>
  <div class="course" v-loading.fullscreen.lock="loading">
    <div class="course_body">
      <div class="module">

        <ThemeCard
          v-for="item in themes"
          :item="item"
          :key="item.id"
        />

        <div class="text-center">
          <button
            v-if="!testing"
            @click="prev"
            class="course-details-prev-btn"
          >
            {{ $t("Back") }}
          </button>
          <button
            v-if="!testing && !lastModule"
            @click="next"
            class="course-details-next-btn"
            :class="{
              disabled: !nextModuleAvailable
            }"
          >
            {{ $t('Further') }}
          </button>
<!--          <button-->
<!--            v-if="!testing && lastModule"-->
<!--            @click="$emit('quiz')"-->
<!--            class="course-details-next-btn"-->
<!--          >-->
<!--            {{ $t('Further') }}-->
<!--          </button>-->
        </div>

        <div
          v-if="testing"
          class="module_item testing"
          :class="{
            'active': testing.available && testing.score === 0,
            'success': testResult,
            'fail': testing.score > 0 && !testResult,
            'noaccess': !testing.available
          }">
          <!--            <div class="module_item_corner" v-if="!testing.available"></div>-->
          <!--            <div class="module_item_corner" v-else>-->
          <!--              <img src="@/assets/course/clock-icon.svg" alt="">{{ testing.duration }} минут-->
          <!--            </div>-->
          <!--            <div class="module_item_corner" v-else>-->
          <!--                Результат:-->
          <!--            </div>-->
          <div class="module_item_status">
            <div v-if="!testing.available">
              <img src="@/assets/course/close-icon.svg" alt="">
            </div>
            <div v-else-if="testing.score === 0">
              <img src="@/assets/course/rotation-icon.svg" alt="">
            </div>
            <div v-else-if="testResult">
              <img src="@/assets/course/success-icon.svg" alt="">
            </div>
            <div v-else>
              <!--                    <img src="@/assets/course/close-icon.svg" alt="">-->
            </div>
          </div>
          <div class="module_item_info">
            <div class="name">
              Тестирование
            </div>
            <div class="buttons" v-if="testing.available">
              <router-link
                :to="{
                  name: 'course-exercise',
                  params: { slug: $route.params.slug, quiz: testing.id },
                  query: { page: $route.query.page, type: $route.query.type }
                }"
                class="btn"
              >
                Начать
              </router-link>
              <button class="btn" v-if="false">
                {{ $t("Passed_course_module") }}
              </button>
              <button class="btn" v-if="false">
                Не пройдено
              </button>
              <div class="test-result" v-if="testing.score > 0">
                <div class="test-result_item">
                  {{ $t("Passed_course_module") }}: {{ testing['passedDate'] }}
                </div>
                <span></span>
                <div class="test-result_item">
                  Проходной балл: {{ testing['minScore'] }}%
                </div>
              </div>
              <div class="retry" v-if="testing.score > 0 && testing.score < testing['minScore']">
                Пройти еще раз
              </div>
              <div class="result" v-if="testing.score > 0">
                {{ testing.score }}%
              </div>
            </div>
            <div class="info" v-else>
              <div class="info_text">
                {{ $t('You_cannot_start_until_you_have_completed_the_previous_topic') }}
              </div>
              <img class="info_icon" src="@/assets/course/info-icon.svg" alt="">
            </div>
          </div>
        </div>
      </div>
    </div>

    <SideMenuNew />
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import SideMenuNew from "@/components/course/SideMenuNew.vue";
import ThemeCard from "@/components/course/ThemeCard.vue";

export default {
  name: "CourseModule",
  components: {ThemeCard, SideMenuNew},
  data() {
    return {
      testing: null,
    }
  },
  mounted() {
    if (!this.course) {
      this.fetchCourse(this.$route.params.slug);
    }
  },
  methods: {
    ...mapActions({
      fetchCourse: 'fetchCourse',
    }),
    prev() {
      if (Number(this.$route.params.module) === this.course?.modules[0].id) {
        this.$router.push({
          name: 'about-course',
          params: {
            slug: this.$route.params.slug
          }
        })
      } else {
        const currentIdx = this.course?.modules.indexOf(this.currentModule);
        const prevModule = this.course?.modules[currentIdx - 1];
        this.$router.push({
          name: 'course-module',
          params: {
            slug: this.$route.params.slug,
            module: prevModule.id
          }
        })
      }
    },
    next() {
      if (this.nextModuleAvailable) {
        this.$router.push({
          name: 'course-module',
          params: {
            slug: this.$route.params.slug,
            module: this.nextModule.id
          }
        })
      }
    },
  },
  computed: {
    ...mapGetters([
      'isCourseLoading',
      'getCourse',
    ]),
    loading() {
      return this.isCourseLoading
    },
    course() {
      return this.getCourse
    },
    themes() {
      return this.course?.modules.find(({id}) => Number(this.$route.params.module) === id).themes;
    },
    testResult() {
      return this.course?.quiz.score >= this.getCourse?.quiz.minScore
    },
    currentModule() {
      return this.course?.modules.find(({id}) => Number(this.$route.params.module) === id);
    },
    nextModule() {
      const idx = this.course?.modules.indexOf(this.currentModule);
      const nextIndex = (idx + 1) % this.course?.modules.length;
      return this.course?.modules[nextIndex];
    },
    nextModuleAvailable() {
      return this.nextModule?.themes[0].available;
    },
    lastModule() {
      const lastModuleId = this.course?.modules[this.course?.modules.length - 1].id;
      return Number(this.$route.params.module) === lastModuleId;
    },
  },
}
</script>

<style lang="scss" scoped>

</style>
