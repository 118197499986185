<template>
  <div
    class="module_item"
    :class="{'active': item.progress >= 0 && item.progress !== 100, 'noaccess': !item.available}"
    @click="item.available ? openChapter(item) : ''"
  >
    <div class="module_item_status">
      <div v-if="item.score || item.progress === 100">
        ✌️
      </div>
      <div v-else-if="item.progress >= 0">
        <img src="@/assets/course/rotation-icon.svg" alt="">
      </div>
      <div v-else-if="!item.available">
        <img src="@/assets/course/close-icon.svg" alt="">
      </div>
    </div>
    <div class="module_item_info">
      <div class="name">{{ item.name }}</div>
      <div class="buttons" v-if="item.available">
        <template v-if="item.progress >= 0 && item.result === 0">
          <div class="process" v-if="item.progress > 0">
            <div class="process_finished" :style="'width:' + item.progress + '%'">
              {{ $t("In_process") }}
            </div>
            <div class="process_score">
              {{ item.progress }}%
            </div>
          </div>
          <div v-else>
            <button class="btn">
              Начать
            </button>
          </div>
        </template>
        <template v-else>
          <button class="btn">
            {{ $t("Passed_course_module") }}
          </button>
          <button class="btn success" v-if="item.score">
            Результат: {{ item.score }}%
          </button>
        </template>
      </div>
      <div class="info" v-else>
        <div class="info_text">
          {{ $t('You_cannot_start_until_you_have_completed_the_previous_topic') }}
        </div>
        <img class="info_icon" src="@/assets/course/info-icon.svg" alt="">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ThemeCard',
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  methods: {
    openChapter (item) {
      this.$router.push({
        name: 'course-theme',
        params: {
          slug: this.$route.params.slug,
          module: item.chapter_id,
          theme: item.id,
        },
      });
    }
  }
}
</script>

<style lang="scss" scoped>
.module {
  &_item {
    background: #fff;
    padding: 25px;
    display: flex;
    align-items: flex-start;
    gap: 25px;
    border-radius: 20px;
    color: #0B1F35;
    margin-bottom: 20px;
    position: relative;
    overflow: hidden;
    cursor: pointer;

    &.testing {
      cursor: inherit;
    }

    &_status {
      width: 20px;
      font-size: 20px;
      text-align: center;
      line-height: 1em;
    }

    .buttons {
      display: flex;
      align-items: center;
      gap: 8px;
    }

    &_corner {
      background: rgba(255, 255, 255, 0.16);
      border-radius: 0px 0px 0px 20px;
      position: absolute;
      top: 0;
      right: 0;
      padding: 12px 16px;
      width: 158px;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 12px;
    }

    &.fail {
      .module_item_corner {
        background: rgba(11, 31, 53, 0.1);
      }

      .btn {
        background: rgba(11, 31, 53, 0.1);
        color: #0B1F35;
        cursor: default;
      }

      &:hover {
        .btn {
          color: #fff;
        }
      }
    }

    &.active, &.success, &:hover {
      background: #007BFC;
      color: #fff;

      .process {
        background: rgba(255, 255, 255, 0.16);

        &_finished {
          color: #007BFC;
          background: #fff;
        }

        &_score {
          color: #fff;
        }
      }

      .name, .result, .retry {
        color: #fff;
      }
    }

    &.success {
      background: #16C95D;

      .btn {
        background: rgba(255, 255, 255, 0.16);
        color: #fff;
        cursor: default;
      }
    }

    &_info {
      width: 100%;

      .name {
        font-size: 18px;
        line-height: 22px;
        color: #0B1F35;
        font-weight: 600;
        margin-bottom: 12px;
      }

      .btn {
        background: #E9F4FF;
        border-radius: 20px;
        padding: 10px 20px;
        color: #007BFC;
        margin-right: 8px;
        line-height: 1em;

        &.success {
          background: #16C95D;
          color: #fff;
        }
      }

      .process {
        background: #F6F7F8;
        border-radius: 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 300px;
        height: 36px;
        font-size: 14px;
        font-weight: 500;
        margin-top: 17px;

        &_finished {
          background: #16C95D;
          border-radius: 20px;
          color: #fff;
          height: 100%;
          display: flex;
          align-items: center;
          padding-left: 13px;
          // overflow: hidden;
          white-space: nowrap;
          width: 10%;
          min-width: 40px;
        }

        &_score {
          padding-right: 13px;
          color: #2B2D33;
        }
      }

      .info {
        display: flex;
        align-items: center;
        gap: 18px;

        &_text {
          font-weight: 500;
        }

        &_icon {
          cursor: pointer;
        }
      }
    }

    .test-result {
      display: flex;
      align-items: center;
      gap: 13px;
      font-weight: 500;
      font-size: 14px;
      margin-left: 22px;

      span {
        background: #fff;
        height: 16px;
        width: 2px;
        border-radius: 2px;;
      }
    }

    .result {
      width: 108px;
      text-align: center;
      font-size: 32px;
      font-weight: 600;
      color: #007BFC;
      margin-left: auto;
    }

    .retry {
      color: #007BFC;
      font-weight: 500;
      cursor: pointer;
      margin: auto;
    }

    &.noaccess {
      background: #fff;
      color: #B4D2F2;
      cursor: not-allowed;

      .name {
        color: #B4D2F2;
      }

      .module_item_status img {
        opacity: 0.5;
      }
    }
  }
}
</style>
